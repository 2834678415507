body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Arial, sans-serif;
    color: var(--text-color);
    scroll-behavior: smooth;
}

.about_us_page p {
    margin: 0;
}

.m-0 {
    margin: 0;
}

.about_us_page .banner {
    /* background-image: url('https://images.unsplash.com/photo-1497366216548-37526070297c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'); */
    background-size: cover;
    background-position: center;
    height: 23rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.about_us_page .banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: rgba(76, 175, 80, 0.7); */
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.about_us_page .banner-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: white;
}

.about_us_page h1 {
    margin-top: 0;
    font-size: 4em;
    margin-bottom: 0.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.about_us_page .subtitle {
    font-size: 1.5em;
    /* margin-bottom: 1em; */
}

.about_us_page .cta-button {
    padding: 12px 24px;
    font-size: 1.2em;
    background-color: white;
    color: var(--main-color);
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.about_us_page .cta-button:hover {
    background-color: var(--secondary-color);
    color: white;
    transform: scale(1.05);
}

.about_us_page .founder {
    background-color: var(--light-bg);
}

.about_us_page .founder .max-width {
    display: flex;
    align-items: center;
    padding: 4em 2em;
    background-color: var(--light-bg);
}

.about_us_page .founder .image-content {
    border-radius: 50%;
    margin-right: 2em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.about_us_page .founder img {
    border-radius: 50%;
    transform: scale(1.05);
    /* margin-right: 2em; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.about_us_page .founder img:hover {
    transform: scale(1.10);
}

.about_us_page .mission-values-vision {
    background: linear-gradient(135deg, var(--main-color) 0%, var(--secondary-color) 100%);
    color: white;
}

.about_us_page .mission-values-vision .max-width {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 4em 2em;
}

.about_us_page .section {
    text-align: center;
    flex: 1;
    min-width: 250px;
    margin: 1em;
    padding: 2em;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
}

.about_us_page .section p {
    line-height: 1.5;
}

.about_us_page .section-icon {
    font-size: 3em;
    margin-bottom: 0.5em;
}

.about_us_page .section:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.about_us_page .section h2 {
    color: white;
    /* border-bottom: 2px solid white; */
    padding-bottom: 0.5em;
    margin-bottom: 1em;
}

.about_us_page ul {
    list-style-type: none;
    padding: 0;
}

.about_us_page li {
    margin-bottom: 0.5em;
}


.about_us_page .value-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Affiche 3 colonnes */
    gap: 20px;
    /* Espacement entre les éléments */
}


.about_us_page .value-list li {
    text-align: center;
    font-size: 1.2em;
}

/* Vue mobile : 1 élément par ligne en dessous de 768px */
@media (max-width: 768px) {
   
.about_us_page .value-list {
        grid-template-columns: 1fr;
        /* Passe à une seule colonne */
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}