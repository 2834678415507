#complete-references .references-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#complete-references .references {
    width: 100%;
}

#complete-references h1,
#complete-references h2 {
    color: var(--main-color);
}

#complete-references h2 {
    margin-top: 0;
    margin-bottom: 1rem;
}

#complete-references {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#complete-references h3 {
    color: var(--main-color);
    border-bottom: 2px solid var(--main-color);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
}

#complete-references .reference-list {
    width: 100% !important;
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

@media (min-width: 587px) {
    #complete-references .reference-list {
        width: 100% !important;
        gap: 1rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

#complete-references .reference {
    background-color: #e9f2ea;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
}


@media screen and (max-width: 587px) {
    #complete-references .reference {
        text-align: center;
        display: block;
    }

}

#complete-references .reference-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 1rem;
    object-fit: cover;
}

#complete-references .reference-content {
    flex: 1;
}

#complete-references .reference-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
}