.summary-references {
    margin-top: 3rem;
    background-color: var(--main-color);
    color: white;
    padding: 2rem;
    padding-bottom: 1.5rem;
    text-align: center;
}

.summary-references h3 {
    font-size: 2rem;
}

.summary-references .reference-section {
    margin-top: 2rem;
}
.summary-references .reference-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}
.summary-references .reference-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 0.5rem;
    width: 300px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.summary-references .reference-card img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1rem;
}
.summary-references .reference-info {
    text-align: left;
    flex-grow: 1;
}
.summary-references .reference-info h4 {
    margin: 0;
    font-size: 1rem;
    color: #666;
    font-weight: 700;
}
.summary-references .reference-info p {
    margin: 0.2rem 0;
    font-size: 0.8rem;
    color: #666;
}