.recruitment-process {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
}

#recruitment-process h1,#recruitment-process h2 {
    color:  var(--main-color);
}

#recruitment-process {
    height: 400px;
    margin-bottom: 3rem;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.recruitment-process .content-image {
    flex: 1;
    max-width: 50%;
}

.recruitment-process .content-image .image_visualisation{
    height: 400px;

    gap: 1rem;
    display: grid; 
    grid-template-columns: auto auto auto;
}

.recruitment-process .content-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.recruitment-process .content-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #e9f2ea;
    padding: 1.5rem;
    border-radius: 8px;
}
.recruitment-process .content-text h2 {
    margin-top: 0;
}

.recruitment-process {
    flex-direction: row-reverse;
}

@media screen and (max-width: 1040px) {
    #recruitment-process {
        height: auto;
        min-height: 445px;
        gap: 2rem;
        display: grid;
        grid-template-columns: 
        repeat(auto-fit, minmax(250px, 1fr));
    }

    .recruitment-process .content-image {
        flex: 1;
        max-width:100%;
        display: none;
    }
}


#recruitment-process:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
