.app-bar-container {
    
}

.app-bar-container  .active {
    color: #4CAF50 !important;
    font-weight: 800;
}

.app-bar-container  .inactif {
    color: #000 !important;
}

.app-bar-container  .inactif:hover {
    color: #4CAF50 !important;
    font-weight: 800;
}