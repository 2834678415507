.testimonial-image {
    width: 140px !important;
    height: 140px !important;
    margin: 20px auto !important;
    border-radius: 50% !important;
    object-fit: cover !important;
  }
  
  .carousel .slide {
    background: transparent !important;
  }
  
  .carousel .control-dots {
    display: none;
  }