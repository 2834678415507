.why-choose-me {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
}
#why-choose-me h1,#why-choose-me h2 {
    color:  var(--main-color);
}

#why-choose-me {
    margin-bottom: 3rem;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.why-choose-me .content-image {
    flex: 1;
    max-width: 50%;
}
.why-choose-me .content-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.why-choose-me .content-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #e9f2ea;
    padding: 1.5rem;
    border-radius: 8px;
}
.why-choose-me .content-text h2 {
    margin-top: 0;
}

.why-choose-me {
    /* height: 400px; */
    flex-direction: row;
}


@media screen and (max-width: 1040px) {
    .why-choose-me {
        height: auto;
        min-height: 400px;
        gap: 2rem;
        display: grid;
        grid-template-columns: 
        repeat(auto-fit, minmax(250px, 1fr));
    }

    .why-choose-me .content-image {
        flex: 1;
        max-width:100%;
        display: none;
    }
}


.why-choose-list, .process-steps {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}
.why-choose-list li, .process-steps li {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    position: relative;
}
.why-choose-list li:before, .process-steps li:before {
    content: "✓";
    color: var(--main-color);
    position: absolute;
    left: 0;
}

#why-choose-me:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
