.home-page h2 {
    font-size: 2rem;
}

.home-page .company-intro {
    background-color: white;
    padding: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}


.home-page .services {
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    background-color: white;
}
.home-page .service {
    text-align: center;
    max-width: 400px; /* Increased from 300px */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 1rem;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
}
.home-page .service:hover {
    transform: translateY(-5px);
}
.home-page .service img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}
.home-page .service h3 {
    color: var(--main-color);
}
.home-page  .btn {
    background-color: var(--main-color);
    color: white;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border-radius: 4px;
    display: inline-block;
    margin-top: 1rem;
}