body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Arial, sans-serif;
    color: var(--text-color);
    scroll-behavior: smooth;
}

.contact_us_page p {
    margin: 0;
}

.contact_us_page .banner {
    /* background-image: url('https://images.unsplash.com/photo-1497366216548-37526070297c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'); */
    background-size: cover;
    background-position: center;
    height: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.contact_us_page .banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: rgba(76, 175, 80, 0.7); */
    background: rgba(0, 0, 0, 0.4);

    z-index: 1;
}

.contact_us_page .banner-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: white;
}

.contact_us_page .banner-buttons {
    display: flex;
    gap: 30px;
    z-index: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact_us_page .banner-button {
    padding: 15px 30px;
    font-size: 1.2em;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
}

.contact_us_page .whatsapp-button {
    background-color: #25D366;
    color: white;
}

.contact_us_page .form-button {
    background-color: #4CAF50;
    color: white;
}

.contact_us_page .whatsapp-button:hover {
    background-color: #128C7E;
    transform: scale(1.05);
}

.contact_us_page .form-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

.contact_us_page h1 {
    margin-top: 0;
    font-size: 3rem;
    margin-bottom: 0.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.contact_us_page .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
}

.contact_us_page h2 {
    color: #4CAF50;
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 30px;
}

.contact_us_page ul {
    background-color: #e8f5e9;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact_us_page ul li {
    margin-bottom: 15px;
    font-size: 1.1em;
}

.contact_us_page form {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.contact_us_page label {
    display: block;
    margin-top: 15px;
    font-weight: bold;
    color: #333;
}

.contact_us_page input[type="text"],
input[type="email"],
textarea,
select {
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
}

.contact_us_page button[type="submit"] {
    display: block;
    width: 100%;
    padding: 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    font-size: 1.2em;
    transition: background-color 0.3s ease;
}

.contact_us_page button[type="submit"]:hover {
    background-color: #45a049;
}

.contact_us_page #map {
    height: 400px;
    margin-top: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact_us_page .loading {
    display: none;
    text-align: center;
    margin-top: 30px;
}

.contact_us_page .loading-spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #4CAF50;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 400px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 400px;
}

.gmap_iframe {
    width: 100% !important;
    height: 400px !important;
}